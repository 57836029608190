const PortfoliolList = [
    {
      title: "Vizag Profiles",
      body: "Vizag Profiles is a Group of companies with over 25 years of experience in steel (handling, trading and manufacturing) Real estate and Logistics. The company has been able to set up its own unique brand and identity in the industry, which has led to unprecedented growth for the past few years.\nThe website is a dynamic responsive webapplication to manage their group of companies. The web application built with API’s has the capability of future application integrations enabling the posibility of an integrated Mobile App.",
      webimageUrl: require("../images/portfolio/1.svg").default,
      mobimageUrl: require("../images/portfolio/1a.svg").default,
    },
    {
        title: "Visakha Paramacity",
        body: "India’s most successful public & private partnership project developed by Visakha Pharmacity Ltd, a joint venture company between Ramky Group & Andhra Pradesh Industrial Infrastructure Corporation (APIIC), a Govt. of Andhra Pradesh undertaking.\nThe website is a dynamic webapplication to manage the company day to day affairs. It’s highly secure and dynamic enabling seamless integration and scalability to the company’s website.",
        webimageUrl: require("../images/portfolio/2.svg").default,
        mobimageUrl: require("../images/portfolio/2a.svg").default,
      },
      {
        title: "F9 IT",
        body: "Flutter boasts of a huge open source community with many packages at pub.dev. f9it.com website was formed after being inspired by the plugins created by developers worldwide, we believe in contributing to the open source community and this website is an attempt to make reaching out to our contributions easier. The website is built using flutter as frontend to showcase that flutter is growing and adapting to it is the way forward.",
        webimageUrl: require("../images/portfolio/3.svg").default,
        mobimageUrl: require("../images/portfolio/3a.svg").default,
      },
    // Add more items as needed
  ];
  export default PortfoliolList;