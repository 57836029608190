import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import HeroImage from "../hero/hero-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Images from "../../components/theme-constants/image";
import "./hero.css";
import useHero from "../../hooks/useherosection";
import { APPLICATION_SETTING_IMAGES } from "../../constants";

const Hero = () => {
  const { hero, loading, error } = useHero();
 if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <Box className="hero-section" sx={{ py: 13 }}>
      <Container maxWidth="xl" className="container">
        <Slider {...settings}>
          <Box className="item">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="content" item xs={6}>
              {hero.hero_data
                      .filter(item => item.slug === 'hero-title')
                      .map((item) => (
                <Typography
                  variant="h3"
                  component="h1"
                  gutterBottom
                  align="left"
                >
                  {item.value}
                </Typography>
                  ))}
                {hero.hero_data
                  .filter(item => item.slug === 'hero-image')
                  .map((item) => (
                <img src={`${APPLICATION_SETTING_IMAGES}${item.value}`} alt="F9tech" />
               ))}

                {hero.hero_data
                      .filter(item => item.slug === 'hero-subtitle')
                      .map((item, index) => (
                <Typography variant="body1" gutterBottom>
                 {item.value}
                </Typography>
                 ))}
              </Grid>
              <Grid className="pic" item xs={6}>
                <HeroImage />
              </Grid>
            </Grid>
          </Box>
        </Slider>
      </Container>
    </Box>
  );
};

export default Hero;
