import axios from 'axios';
 

export const getherosection = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}herosection`); 
    return response.data;
  } catch (error) {
    console.error('Error fetching menu:', error);
    throw error;
  }
};

