// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getMisson } from '../services/mission';

let cachedData = null;

const useMisson = () => {
  const [misson, setmisson] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) {
    const fetchData = async () => {
      try {
        const data = await getMisson();
        cachedData = data; 
        setmisson(data);
      } catch (err) {
        console.error('Error fetching menu:', err); 
        setError('Failed to fetch menu'); 
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }
  }, []);

  return { misson, loading, error };
};

export default useMisson;
