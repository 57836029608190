import { useEffect, useState } from "react";
import { getBusinessmodel } from "../services/businessmodelapi";

let cachedData = null; 

const useBusinessmodel = () => {
    const [business, setBusiness] = useState(cachedData || []);
    const [loading, setLoading] = useState(!cachedData);  
    const [error, setError] = useState(null);

    useEffect(() => {
        if (!cachedData) {  // Only fetch if data is not cached
            const fetchData = async () => {
                try {
                    const data = await getBusinessmodel();
                    cachedData = data;  // Cache the data
                    setBusiness(data);
                } catch (err) {
                    console.error('Error fetching blogs:', err);
                    setError('Failed to fetch blogs');
                } finally {
                    setLoading(false);
                }
            };
            fetchData();
        }
    }, []);

    return { business, loading, error };
};

export default useBusinessmodel;
