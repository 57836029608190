import React from "react";
import Sectiontitle from "../../components/section-title";
import { Container, Grid, Box, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Images from "../../components/theme-constants/image";
import ProcessCard from "../custom-cards/process-card";

import DevelopmentProcessList from "../../lists/list-development";
import useDevelopmentProcess from "../../hooks/usedevelopment";
import { APPLICATION_SETTING_IMAGES, SERVICE_IMAGES } from "../../constants";

function DevelopmentProcess() {
    // Select the first 8 items
    const developmentProcess = DevelopmentProcessList.slice(0, 8);
    const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));


    const {development,loading,error} = useDevelopmentProcess();
    
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <Box className="bg-grey development-process" sx={{ py: 13 }}>
            <Container maxWidth="xl" className="container">
                <Sectiontitle
                    data={{
                        mainTitle: "Our Development Process",
                        subTitle: "We believe in developing with agility",
                    }}
                />
                <Grid container spacing={5} justifyContent="center" alignItems="left">
                    <Grid item xs={isSmallScreen ? 12 : 5}  className="development-left">
                        <figure>
                        <CardMedia
                            component="img"
                            image={`${APPLICATION_SETTING_IMAGES}${development.development_process_image}`}
                            alt="Development Process"
                        />

                        </figure>
                    </Grid>
                    <Grid item  xs={isSmallScreen ? 12 : 7} className="development-right">
                        <Grid container spacing={3} justifyContent="center" alignItems="left">
                            {development.development_list.map((item) => (
                                <Grid item xs={isSmallScreen ? 12 : 3} key={item.id}>
                                    <ProcessCard
                                        title={item.title}
                                        body={item.description}
                                        icons={item.sub_title}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default DevelopmentProcess;
