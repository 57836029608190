import React, { Component } from 'react';
import SolutionsTop from '../../components/solutions-blocks/solutions-top';
import WeOffer from '../../components/solutions-blocks/we-offer';
import Blog from '../../components/blog';
import { Helmet } from "react-helmet";
import {DataProvider} from '../../context/menucontext';


class Solutions extends Component {
    render() {
        return (
           <div>
            <DataProvider>
             <Helmet>
                    <title>Business Solutions | F9 Technologies</title>
                    <meta name="keywords" content="business solutions from F9 tech in vizag, business solutions using technologies in Vizag, F9 business solutions in vizag, Innovation business solutions from f9tech in vizag"/> 
                    <meta name='description' content='Find out how F9Techs creative methodology is revolutionising business solutions in Vizag.'/>
                </Helmet>
            <SolutionsTop/>
            <WeOffer/>
            <Blog limit={3} />
            </DataProvider>
           </div>
        );
    }
}

export default Solutions;
