

export const CLIENTELE_IMAGES = `${process.env.REACT_APP_IMAGE_URL}clientele/`;

export const BLOGS_IMAGES = `${process.env.REACT_APP_IMAGE_URL}blogpost/`;

export const APPLICATION_SETTING_IMAGES = `${process.env.REACT_APP_IMAGE_URL}site-images/`;

export const CMS_IMAGES = `${process.env.REACT_APP_IMAGE_URL}inner-pages/`;

export const SERVICE_IMAGES = `${process.env.REACT_APP_IMAGE_URL}services/`;

export const GALLERY_IMAGES = `${process.env.REACT_APP_IMAGE_URL}photogallery/`;
