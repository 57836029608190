const BusinessModelList = [
    {
        title: "Resource Model",
        body: "Resource model allows you to have the benefit of an entire remote team, but with the control to meet deadlines, change directions, and create your product vision. The best part? You don't need to invest in infrastructure for the remote team.",
        imageUrl: require("../images/business/1.svg").default,
      },
      {
        title: "Hour Model",
        body: "Hourly Model is a fixed hourly engagement model, which means our team is willing to work on your project for any number of hours, based on the hourly rate. This engagement model ensures that you don't have to worry about spending unnecessary time on tasks which may not be required or desired. You can focus on what's important to you.",
        imageUrl: require("../images/business/2.svg").default,
      },
      {
        title: "Fixed Scope Model",
        body: "Fixed Scope Model is perfect if you know what you want and need. It makes it easier to give a straightforward estimate and follow through with the plan strictly. In this fixed scope model, there are only the features that are planned for the app. If a new feature is needed, it is added to the scope at a later date.",
        imageUrl: require("../images/business/3.svg").default,
      },
      {
        title: "Partnership Model",
        body: "Do you have a great idea and a killer business plan, but not the technical knowledge or resource to implement it? We'll be your technical partner and we'll help you execute your project. We also partner with companies based out of India who are willing to outsource work to teams in India.",
        imageUrl: require("../images/business/4.svg").default,
      },
     
  
  // Add more items as needed
];
export default BusinessModelList;
