import React from "react";
import { Container, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import { useDataContext } from "../../../context/menucontext";
import Images from "../../../components/theme-constants/image";
import { CMS_IMAGES } from "../../../constants";

function SolutionsTop() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));  
  const { data, loading, error } = useDataContext();

  // Ensure we safely access the data and find the required item by slug
  const item = Array.isArray(data) ? data.find((item) => item.slug === "solutions") : null;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!item) return <p>Item not found!</p>;

  return (
    <Box className="solution-top-section" sx={{ pt: 13 }}>
      <Container maxWidth="xl" className="container">
        <Box className="item">
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid 
              item 
              xs={isSmallScreen ? 12 : 6} 
              className={isSmallScreen ? "order2" : "content"}
            >
              <Typography variant="h3" component="h2" gutterBottom>
                <span>{item.banner_title}</span>
              </Typography>
              <Typography variant="body1" gutterBottom>
                {item.short_description}
              </Typography>
            </Grid>
            <Grid 
              item 
              xs={isSmallScreen ? 12 : 5} 
              className={isSmallScreen ? "order1" : "pic"}
            >
              <figure>
                <CardMedia
                  component="img"
                  image={`${CMS_IMAGES}${item.banner_image}`}
                  alt="Solutions"
                />
              </figure>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}

export default SolutionsTop;
