// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getportfolio } from '../services/portfolioapi';
let cachedData = null;

const usePortfolio = () => {
  const [portfolio, setportfolio] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) {
    const fetchData = async () => {
      try {
        const data = await getportfolio();
        cachedData = data; 
        setportfolio(data);
      } catch (err) {
        console.error('Error fetching menu:', err); 
        setError('Failed to fetch menu');
      } finally {
        setLoading(false); 
      }
    };

    fetchData();
  }
  }, []);

  return { portfolio, loading, error };
};

export default usePortfolio;
