import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";
import CardMedia from '@mui/material/CardMedia';
import useExperience from "../../hooks/useexperience";
import {APPLICATION_SETTING_IMAGES} from '../../constants.js';

const Welcome = () => {
  const { experience, loading, error } = useExperience();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Box className="welcome-section pt-5">
      <Container maxWidth="xl" className="container">
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="start"
        >
          <Grid className="content" item xs={4}>
            <figure>
              <CardMedia
                component="img"
                image={`${APPLICATION_SETTING_IMAGES}${experience.experience_logo}`} 
                alt="F9tech"
              />
            </figure>
          </Grid>
          <Grid className="pic" item xs={8}>
            <Typography
              variant="h3"
              component="div"
              gutterBottom
              alignItems="flex-end"
              className="welcome-title"
            >
              {experience.experience_title}
            </Typography>
            <Typography variant="body1" gutterBottom>
              {experience.experience_description}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Welcome;
