import React from "react";
import { Typography, Grid } from "@mui/material";
import "./section-title.css";
const Sectiontitle = ({ data }) => {
  return (
    <Grid
      className="section-title"
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="h2" component="h2">
        {data.mainTitle}
      </Typography>
      <Typography component="p" className="text-primary">{data.subTitle}</Typography>
    </Grid>
  );
};
export default Sectiontitle;
