import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, Divider, styled } from "@mui/material";
import InnerBanner from '../components/inner-banner/innerbanner';
import useBlogs from '../hooks/useblogs';
import { BLOGS_IMAGES } from '../constants';

const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 0),
  backgroundColor: "#f8f9fa",
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  maxWidth: "md",
  margin: "0 auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

const BannerImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxHeight: '400px',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(4),
}));

const ContentBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  lineHeight: 1.8,
  fontSize: '1rem',
}));

const AuthorSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: "#f0f0f0",
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
}));

const AuthorImage = styled('img')(({ theme }) => ({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
  marginBottom: theme.spacing(1),
}));

const AuthorName = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.1rem',
}));

const PostDate = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.875rem',
}));

// Function to format date to DD-MM-YYYY
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return `${String(date.getDate()).padStart(2, '0')}-${String(date.getMonth() + 1).padStart(2, '0')}-${date.getFullYear()}`;
};

const BlogDetail = () => {
  const { id } = useParams();
  const { blogs, loading, error } = useBlogs();

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const item = blogs.find((s) => s.id === Number(id));

  if (!item) return <Typography color="error">Item not found!</Typography>;

  return (
    <>
      <InnerBanner 
        bannerImage={item.image} 
        title={item.title} 
        tagline={item.banner_tagline} 
        path={BLOGS_IMAGES} 
      />

      <Section component="section">
        <ContentContainer>
          <BannerImage 
            src={`${BLOGS_IMAGES}${item.image}`} 
            alt={item.title} 
          />
          
          <Title variant="h4" component="h1">
            {item.title || "Coming Soon.."}
          </Title>

          <PostDate variant="body2" align="center">
            {formatDate(item.post_date)}
          </PostDate>

          <Divider sx={{ my: 3 }} />

          <ContentBox>
            <Typography variant="h6" component="div" gutterBottom>
              Short Description
            </Typography>
            <Box 
              dangerouslySetInnerHTML={{ __html: item.short_description || "Coming Soon.." }} 
            />
          </ContentBox>

          <ContentBox>
            <Typography variant="h6" component="div" gutterBottom>
              Full Description
            </Typography>
            <Box 
              dangerouslySetInnerHTML={{ __html: item.description || "Coming Soon.." }} 
            />
          </ContentBox>

          {item.author_image && (
            <AuthorSection>
              <AuthorImage 
                src={`${BLOGS_IMAGES}${item.author_image}`} 
                alt="Author" 
              />
              <AuthorName>{item.author_name || "Author"}</AuthorName>
              <Typography variant="body2" color="textSecondary">
                {item.author_bio || "Author bio goes here."}
              </Typography>
            </AuthorSection>
          )}
        </ContentContainer>
      </Section>
    </>
  );
};

export default BlogDetail;
