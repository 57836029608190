// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getExperience } from '../services/experienceapi';
let cachedData = null;

const useExperience = () => {
  const [experience, setexperience] = useState(cachedData || []);
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);


  useEffect(() => {
    if (!cachedData) {
      const fetchData = async()=>{
          try {
              const data = await getExperience();
              cachedData = data; 
              setexperience(data);
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }
  }, []);

  return { experience, loading, error };
};

export default useExperience;
