// services/apiService.js
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_URL);

export const fetchDataFromApi = async (endpoint) => {
  try {
    const response = await axios.get(`${API_URL}${endpoint}`);
    return response.data;
  } catch (error) {
    console.error('Failed to fetch data:', error);
    throw error;
  }
};
