import { useEffect,useState } from "react";
import { getBlogs } from "../services/blogsapi";

let cachedData = null;

const useBlogs =() => {
    const [blogs, setBlogs] = useState(cachedData || []);
    const [loading, setLoading] = useState(!cachedData);
    const [error, setError] = useState(null);
    useEffect(()=>{
        if (!cachedData) {
        const fetchData = async()=>{
            try {
                const data = await getBlogs();
                cachedData = data; 
                setBlogs(data);
            }catch(err){
                console.error('Error fetching blogs:',err);
                setError('Failed to fetch blogs');
            }finally{
                setLoading(false);
            }
        }
        fetchData();
    }
    },[]);

    return {blogs, loading, error};

}

export default useBlogs;