// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getExpertise } from '../services/expertiseapi';

let cachedData = null; 

const useExpertise = () => {
  const [photo, setphoto] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) {
    const fetchData = async () => {
      try {
        const data = await getExpertise();
        cachedData = data; 
        setphoto(data);
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }
  }, []);

  return { photo, loading, error };
};

export default useExpertise;
