import React from "react";
import { Container, Grid, Typography, Box, useMediaQuery } from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";
import { useDataContext } from "../../../context/menucontext";
import { CMS_IMAGES } from "../../../constants";


function AboutTop() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const {data, loading,error} = useDataContext();

  const item = Array.isArray(data) ? data.find((item) => item.slug === "about-us") : null;

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
  if (!item) return <p>Item not found!</p>;
   
    return (
      <Box className="about-top-section" sx={{ pt:13 }}>
        <Container maxWidth="xl" className="container">
        <Box className="item">
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid className="content order2" item xs={isSmallScreen ? 12 : 7} >
                  <Typography
                    variant="h3"
                    component="h1"
                    gutterBottom
                    alignItems="flex-end"
                    dangerouslySetInnerHTML={{ __html: item.banner_title }}>
                  
                  </Typography>
                  
                  <Typography variant="body1" gutterBottom>
                  {item.short_description}
                  </Typography>
                </Grid>
                <Grid className="pic order1" item xs={isSmallScreen ? 12 : 5} >
                 
                  <figure><CardMedia
                  component="img"
                 
                  image={`${CMS_IMAGES}${item.banner_image}`}
                  alt="F9tech"
                /></figure>
                 
                </Grid>
              </Grid>
            </Box>
        </Container>
      </Box>
    );
  }
  
  export default AboutTop;