import React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { List, Grid, useMediaQuery, ListItem } from "@mui/material";
import { Link } from "react-router-dom"; // Import Link

// Function to truncate a string to a specified length
const truncate = (str, maxLength) =>
  str.length > maxLength ? str.substring(0, maxLength - 3) + "..." : str;

// Removes all HTML tags
const seriouslyRenderHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, ""); // Removes all HTML tags
};

export default function ServiceCardList({ title, body, imageUrl, id }) {
  const truncatedTitle = truncate(title, 24); // Truncate the title
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const plainText = seriouslyRenderHtml(body);

  // Split the plain text into an array based on line breaks or custom delimiters
  const listItems = plainText.split("\n").filter((item) => item.trim() !== ""); // Adjust delimiter as needed

  return (
    <Link to={`/services/${id}`} style={{ textDecoration: "none" }}> {/* Link added here */}
      <Card sx={{ display: "flex" }} className="services-list-card">
        <Grid container spacing={5} justifyContent="space-between" alignItems="start">
          <Grid item xs={isSmallScreen ? 12 : 8}>
            <Typography component="div" variant="h3">
              {truncatedTitle}
            </Typography>
            <List>
              {listItems.map((item, index) => (
                <ListItem key={index}>
                  <Typography>
                    {item}
                  </Typography>
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid item xs={isSmallScreen ? 12 : 4}>
            <CardMedia
              component="img"
              sx={{ width: 151 }}
              image={imageUrl}
              alt={truncatedTitle} // Use the title for better accessibility
            />
          </Grid>
        </Grid>
      </Card>
    </Link>
  );
}
