import React  from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  List,
  ListItem, useMediaQuery
} from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import useMisson from "../../../hooks/usemission";
import { SERVICE_IMAGES } from "../../../constants";


function Vision() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const {misson, loading,error} = useMisson();
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;
   
    return (
      <Box className="vision-section">
        <Container maxWidth="xl" className="container">
        {misson.map((item, index) => (
  <Box className="card" key={index}>
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      {index % 2 === 0 ? (
        <>
          {/* Image on the left for even-indexed items */}
          <Grid className="order1" item>
            <figure>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={`${SERVICE_IMAGES}${item.image}`}
                alt="f9 tech"
              />
            </figure>
          </Grid>
          <Grid className="content order2" item xs={isSmallScreen ? 12 : 7}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                <span dangerouslySetInnerHTML={{ __html: item.description }} />
              </Typography>
            </CardContent>
          </Grid>
        </>
      ) : (
        <>
          {/* Image on the right for odd-indexed items */}
          <Grid className="content order1" item xs={isSmallScreen ? 12 : 7}>
            <CardContent sx={{ flex: "1 0 auto" }}>
              <Typography component="div" variant="h5">
                <span dangerouslySetInnerHTML={{ __html: item.description }} />
              </Typography>
            </CardContent>
          </Grid>
          <Grid className="order2" item>
            <figure>
              <CardMedia
                component="img"
                sx={{ width: 151 }}
                image={`${SERVICE_IMAGES}${item.image}`}
                alt="f9 tech"
              />
            </figure>
          </Grid>
        </>
      )}
    </Grid>
  </Box>
))}


          <Box className="card">
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 4} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                  <List>
                    <ListItem><CheckBoxIcon /> Passion</ListItem>
                    <ListItem><CheckBoxIcon /> Employee Satisfaction</ListItem>
                    <ListItem><CheckBoxIcon /> Integrity</ListItem>
                    <ListItem><CheckBoxIcon /> Humble</ListItem>
                  </List>
                </CardContent>
              </Grid>
              <Grid className="text-center order1" item xs={isSmallScreen ? 12 : 4} >
                <figure>
                  <CardMedia
                    component="img"
                    sx={{ width: 151 }}
                    image={Images.V3.default}
                    alt="f9 tech"
                  />
                </figure>
              </Grid>
              <Grid className="content order2" item xs={isSmallScreen ? 12 : 4} >
                <CardContent sx={{ flex: "1 0 auto" }}>
                <List>
                    <ListItem><CheckBoxIcon /> Customer Focused</ListItem>
                    <ListItem><CheckBoxIcon /> Discipline</ListItem>
                    <ListItem><CheckBoxIcon /> Collaboration</ListItem>
                    <ListItem><CheckBoxIcon /> Commitment</ListItem>
                  </List>
                </CardContent>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
   );
  }
  
  export default Vision;