import React from "react";
import { Grid, Typography, Box, useMediaQuery } from "@mui/material";
import Images from "../../../components/theme-constants/image";
import CardMedia from "@mui/material/CardMedia";
import useSolutions from "../../../hooks/usesolutions";
import { CLIENTELE_IMAGES } from "../../../constants";

function WeOffer() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const {solution,loading,error} = useSolutions();
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

 


  return (
    <Box className="weoffer-section" sx={{ py: 13 }}>
      <Typography
        variant="h1"
        component={"h1"}
        className="page-title"
        align="center"
      >
        Solutions
      </Typography>
      {solution.map((item,index) => (
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="block"
      >
        <Grid className="pic order1" item xs={isSmallScreen ? 12 : 6} key={index} sx={{ p: 0 }}>
          <figure>
            <CardMedia component="img" image={`${CLIENTELE_IMAGES}${item.image}`} alt="F9tech" />
          </figure>
        </Grid>
        <Grid className="content order2" item xs={isSmallScreen ? 12 : 6} sx={{ p: 0 }}>
          <Typography
            variant="h3"
            component="h2"
            gutterBottom
            alignItems="flex-end"
          >
           {item.title}
          </Typography>
          <Typography variant="body1" gutterBottom>
          {item.sub_title.replace(/<\/?[^>]+(>|$)/g, "")}
          </Typography>
        </Grid>
      </Grid>
      ))}
    </Box>
  );
}

export default WeOffer;
