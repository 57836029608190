import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  Box,
  TextField,
  Typography,
  Snackbar,
} from "@mui/material";
import { Helmet } from "react-helmet";
import axios from "axios";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    description: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'error',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const requiredFields = ['name', 'email', 'subject', 'description'];
    for (const field of requiredFields) {
      if (!formData[field]) {
        setSnackbar({
          open: true,
          message: `Please fill out the ${field} field.`,
          severity: 'error',
        });
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}/submit-contact-form`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setSnackbar({
        open: true,
        message: 'Message sent successfully!',
        severity: 'success',
      });
      setFormData({ name: '', email: '', subject: '', description: '' });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again.',
        severity: 'error',
      });
    }
  }


  return (
    <Box className="contact-page-section" sx={{ py: 13 }}>
      <Helmet>
        <title>Contact Us | F9 Technologies</title>
      </Helmet>

      <Container maxWidth="md">
        <Typography variant="h1" align="center" sx={{ mb: 3 }}>
          Contact Us
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid className="content" item xs={12} md={6} sx={{ mb: 3 }}>
              <TextField
                id="outlined-password-input"
                label="Name"
                name="name"
                type="text"
                value={formData.name}
                onChange={handleChange}
                autoComplete="name"
                fullWidth
              />
            </Grid>
            <Grid className="content" item xs={12} md={6} sx={{ mb: 3 }}>
              <TextField
                id="outlined-password-input"
                label="Email"
                name="email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                autoComplete="email"
                fullWidth
              />
            </Grid>
            <Grid className="content" item xs={12} sx={{ mb: 3 }}>
              <TextField
                id="outlined-password-input"
                label="Subject"
                name="subject"
                type="text"
                value={formData.subject}
                onChange={handleChange}
                autoComplete="subject"
                fullWidth
              />
            </Grid>
            <Grid className="content" item xs={12} sx={{ mb: 3 }}>
              <TextField
                id="outlined-multiline-static"
                label="Description"
                name="description"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Box align="center" sx={{ mt: 3 }}>
            <Button type="submit" variant="contained">
              Contact Us
            </Button>
          </Box>
        </form>
        <Snackbar
          open={snackbar.open}
          message={snackbar.message}
          autoHideDuration={3000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        />

      </Container>
    </Box>
  );
};

export default Contact;
