import React, { Component } from "react";
import { Grid, Container, Box, Typography, useMediaQuery } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Images from "../../components/theme-constants/image";
import logo from "../../logo.svg";
import "./footer.css";
import useFooter from '../../hooks/usefooter';
import { APPLICATION_SETTING_IMAGES } from "../../constants";


function Footer() {
  const isSmallScreen = useMediaQuery(theme => theme.breakpoints.down("sm"));

  const { footer, loading, error } = useFooter();

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <Box>
      <Box className="footer-top">
        <Container maxWidth="xl" className="container">
          <Grid
            container
            spacing={5}
            justifyContent="space-between"
            alignItems="end"
          >
            <Grid item xs={3} className="display-none">
              <Box className="footer-logo">
                <Link href="/">
                  <img
                    src={`${APPLICATION_SETTING_IMAGES}${footer.logo}`}
                    className="App-logo"
                    alt="F9 Info Technologies"
                  />
                </Link>
              </Box>
            </Grid>
            <Grid item className="footer-socail">
              <List>
                {footer.social_links.map((item) => (
                  item.value && (
                    <ListItem key={item.id}>
                      <Link href={item.value}>
                        <CardMedia
                          component="img"
                          image={Images.socialImages[item.slug] || Images.DevelopmentImage.default}
                          alt={item.slug}
                        />
                      </Link>
                    </ListItem>
                  )
                ))}
              </List>

            </Grid>
            <Grid item xs={2} className="display-none">
              &nbsp;
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box className="bg-secondary footer-middle" sx={{ py: 13 }}>
        <Container maxWidth="xl" className="container">
          {" "}
          <Container maxWidth="xl" className="container">
            <Grid
              container
              spacing={5}
              justifyContent="center"
              alignItems="start"
            >
              <Grid item xs={isSmallScreen ? 12 : 3} className="block">
                <Box className="footer-nav">
                  <Typography className="footer-title">SERVICES</Typography>
                  <Box>
                    {footer.footer_links
                      .filter(item => item.slug === 'services')
                      .map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item.value.split('\n').map((word, wordIndex) => (
                            <React.Fragment key={wordIndex}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                        </Typography>
                      ))}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 3} className="block">
                <Box className="footer-nav">
                  <Typography className="footer-title">SOLUTIONS</Typography>
                  <List>
                    {footer.footer_links
                      .filter(item => item.slug === 'footer-solutions')
                      .map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item.value.split('\n').map((word, wordIndex) => (
                            <React.Fragment key={wordIndex}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                        </Typography>
                      ))}
                  </List>
                </Box>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 3} className="block">
                <Box className="footer-nav">
                  <Typography className="footer-title">
                    QUICK LINKS
                  </Typography>
                  <List>
                    {footer.footer_links
                      .filter(item => item.slug === 'footer-links')
                      .map((item, index) => (
                        <Typography key={index} variant="body2">
                          {item.value.split('\n').map((word, wordIndex) => (
                            <React.Fragment key={wordIndex}>
                              {word}
                              <br />
                            </React.Fragment>
                          ))}
                        </Typography>
                      ))}
                  </List>

                </Box>
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 3} className="block">
                <Box className="footer-nav">
                  {footer.contact_details
                    .filter((item) => item.value)
                    .map((item) => (
                      <React.Fragment key={item.slug}>
                        {item.slug.includes('phone') && (
                          <Typography className="footer-phone">
                            <Link href={`tel:${item.value}`}>{item.value}</Link>
                          </Typography>
                        )}

                        {item.slug.includes('email') && (
                          <Typography className="footer-email">
                            <Link href={`mailto:${item.value}`}>{item.value}</Link>
                          </Typography>
                        )}

                        {item.slug === 'address' && (
                          <Typography className="footer-address">
                            {item.value.split('\n').map((line, index) => (
                              <React.Fragment key={index}>
                                {line}
                                <br />
                              </React.Fragment>
                            ))}
                          </Typography>
                        )}

                        {item.slug === 'location-url' && (
                          <Typography className="footer-location">
                            <Link href={item.value} target="_blank" rel="noopener noreferrer">
                              View Location
                            </Link>
                          </Typography>
                        )}

                        {item.slug === 'location-iframe' && (
                          <Box className="footer-map">
                            <iframe
                              src={item.value}
                              title="Location Map"
                              width="100%"
                              height="200"
                              style={{ border: 0 }}
                              allowFullScreen
                              loading="lazy"
                            ></iframe>
                          </Box>
                        )}
                      </React.Fragment>
                    ))}
                </Box>

              </Grid>
            </Grid>
            <Grid className="footer-bottom"
              container
              spacing={5}
              justifyContent="space-between"
              alignItems="end"
            >
              <Grid item xs={isSmallScreen ? 12 : 4} >
                <List>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.KDL.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.BNI.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.CII.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.VS.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.GitHub.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                </List>
              </Grid>
              <Grid item className="footer-content">
                {footer.footer_links
                  .filter(item => item.slug === 'copyright-text')
                  .map(item => (
                    <Typography key={item.slug} variant="body2">
                      {item.value}
                    </Typography>
                  ))}
              </Grid>
              <Grid item xs={isSmallScreen ? 12 : 3} >
                <List>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.DRMCA.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                  <ListItem>
                    <Link href="#">
                      <CardMedia
                        component="img"
                        image={Images.Pro.default}
                        alt="facebook"
                      />
                    </Link>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Container>
        </Container>
      </Box>
    </Box>
  );
}

export default Footer;
