// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getFooter } from '../services/footerapi';

const useFooter = () => {
  const [footer, setfooter] = useState({
    footer_links: [],
    social_links: [],
    contact_details: [],
    logo: null,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getFooter();
        setfooter(data || []); 
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }, []);

  return { footer, loading, error };
};

export default useFooter;
