import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Container, Typography, styled } from "@mui/material";
import useServices from '../hooks/useservices';
import InnerBanner from '../components/inner-banner/innerbanner';
import { SERVICE_IMAGES } from '../constants';

// Styled components
const Section = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 0),
  backgroundColor: "#f8f9fa",
}));

const ContentContainer = styled(Container)(({ theme }) => ({
  maxWidth: "md",
  margin: "0 auto",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(2),
}));

const BannerImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxHeight: '400px',
  objectFit: 'cover',
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const Content = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ServiceDetail = () => {
  const { id } = useParams();
  const { service, loading, error } = useServices();

  if (loading) return <Typography>Loading...</Typography>;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  const item = service.find((s) => s.id === Number(id));

  if (!item) return <Typography color="error">Service not found!</Typography>;

  return (
    <>
      <InnerBanner 
        bannerImage={item.banner_image} 
        title={item.title} 
        tagline={item.banner_tagline} 
        path={SERVICE_IMAGES}
      />
      
      <Section component="section">
        <ContentContainer>
          <BannerImage 
            src={`${SERVICE_IMAGES}${item.image}`} 
            alt={item.title} 
          />

          <Title variant="h4" component="h1">
            {item.title || "Coming Soon..."}
          </Title>

          <Content 
            component="div" 
            dangerouslySetInnerHTML={{ __html: item.short_description || "Coming Soon..." }} 
          />

          <Content 
            component="div" 
            dangerouslySetInnerHTML={{ __html: item.description || "Coming Soon..." }} 
          />
        </ContentContainer>
      </Section>
    </>
  );
};

export default ServiceDetail;
