const Servicecardlist = [
  {
    imageUrl: require("../images/services/1.svg").default,
    title: "Mobile App Development",
    body: "We can help you build beautiful and functional android, ios and cross platform mobile apps for next generation business as per your requirement and within your budget.",
    body1: ["Mobile App UI/UX Design", "Cross-platform App Development", "Progressive Web App Development", "Enterprise Mobility Solutions", "Maintenance and Support"],
  },
  {
    imageUrl: require("../images/services/2.svg").default,
    title: "Web App Development",
    body: "Looking to scale your business? With us, you'll be able to create an integrated web presence to support your mobile apps, iot devices to save time and increase productivity.",
    body1: ["Website Design and Development", "Ecommerce Development", "Custom Application Development", "CMS Development", "Maintenance and Support"],
  },
  {
    imageUrl: require("../images/services/3.svg").default,
    title: "Ui/Ux Design",
    body: "User Interface and User Experience is key in developing a successful application. Our team of experts will help you create intiutive designs which will be loved by your customers and increase user engagement.",
    body1: ["UI Design", "UX Design", "Interaction Design", "Prototyping", "Custom-tailored Designs"],
  },
  {
    imageUrl: require("../images/services/4.svg").default,
    title: "Graphic Design",
    body: "We create engaging content and meaningful experience for your target audience.",
    body1: ["Illustrations", "Logo Design", "Icon Design", "Motion Graphics & Animations", "Creative Designs"],
  },
  {
    imageUrl: require("../images/services/5.svg").default,
    title: "Cloud Computing",
    body: "Providing secured cloud computing solutions to host and maintain your data.",
    body1: ["Cloud app development", "Cloud configuration", "Cloud integration", "Cloud server solutions", "Cloud migration"],
  },
  {
    imageUrl: require("../images/services/6.svg").default,
    title: "Digital Marketing",
    body: "Helping Businesses create a solid digital presence with targetted, expert-led marketing services.⁣⁣",
    body1: ["Search Engine Optimization (SEO)", "Social Media Marketing (SMM)", "Social Media Advertising", "App Store Optimization (ASO)", "Content Writing and Marketing"],
  },
  {
    imageUrl: require("../images/services/7.svg").default,
    title: "Project Management",
    body: "We can manage your project from end to end using agile methodologies to ensure that you're always on the right track.",
    body1: ["Scope statement", "Schedule and timeline", "Resources planning", "Work breakdown structure", "Quality of the project"],
  },
  {
    imageUrl: require("../images/services/8.svg").default,
    title: "Business Solutions",
    body: "Based on your business needs we can suggest and integrate world class business solutions to make your business more profitable.",
    body1: ["BI Ssolutions", "Corporate Email and United Communications", "Electronic Archives", "Portal Solutions and document flow", "DRaaS"],
  },
  
];
export default Servicecardlist;
