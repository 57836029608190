// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { gethomeService } from '../services/homeservicesapi';
let cachedData = null;

const useHomeServices = () => {
  const [homeservice, sethomeservice] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) {
    const fetchData = async () => {
      try {
        const data = await gethomeService();
        cachedData = data; 
        sethomeservice(data);
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }
  }, []);

  return { homeservice, loading, error };
};

export default useHomeServices;
