// Define all images in a single Images object, including social image mappings
const Images = {
  IdeaImage: require("../../images/idea.svg"),
  Logo14: require("../../images/14-logo.svg"),
  AboutTopImage: require("../../images/about.svg"),
  V1: require("../../images/v-1.svg"),
  V2: require("../../images/v-2.svg"),
  V3: require("../../images/v-3.svg"),
  DevelopmentImage: require("../../images/development.svg"),
  Facebook: require("../../images/social/facebook.svg"),
  GitHub: require("../../images/social/github.svg"),
  Instragram: require("../../images/social/instragram.svg"),
  Linkedin: require("../../images/social/linkedin.svg"),
  Twitter: require("../../images/social/twitter.svg"),
  BNI: require("../../images/footer/bni.svg"),
  CII: require("../../images/footer/cii.svg"),
  KDL: require("../../images/footer/kdl.svg"),
  VS: require("../../images/footer/vs.svg"),
  DRMCA: require("../../images/footer/drmca.svg"),
  Pro: require("../../images/footer/pro.svg"),
  Blog1: require("../../images/blog/1.jpg"),
  Blog2: require("../../images/blog/2.jpg"),
  Blog3: require("../../images/blog/3.jpg"),
  S1: require("../../images/offers/1.png"),
  S2: require("../../images/offers/2.png"),
  S3: require("../../images/offers/3.png"),
  S4: require("../../images/offers/4.png"),
  SolutionsImage: require("../../images/solutions.png"),

  // Social images as part of Images object
  socialImages: {
    'facebook-url': require("../../images/social/facebook.svg").default,
    'github-url': require("../../images/social/github.svg").default,
    'instagram-url': require("../../images/social/instragram.svg").default,
    'linkedin-url': require("../../images/social/linkedin.svg").default,
    'twitter-url': require("../../images/social/twitter.svg").default,
  },
};

export default Images;
