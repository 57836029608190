import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import "./service-card.css";

function truncate(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }
  return `${text.slice(0, maxLength)}...`;
}

const seriouslyRenderHtml = (html) => {
  return html.replace(/<\/?[^>]+(>|$)/g, ''); // Removes all HTML tags
};


function ServiceCard({ title = "Title", body = "Body",  imgUrl = "https://www.chicagoandhra.org/images/events/mdf8wljqn.jpg" }) {
  const truncatedTitle = truncate(title, 24);
  const truncatedBody = seriouslyRenderHtml(body);



  return (
    <Card className="service-card">
      <CardActionArea>
        <figure>
          <CardMedia
            component="img"
            image={imgUrl}
            alt="F9tech"
          />
        </figure>
        <CardContent>
          <Typography className="title" gutterBottom variant="h4" component="div">
            {truncatedTitle}
          </Typography>
          <Typography component="p" color="text.secondary">
            {truncatedBody}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default ServiceCard;
