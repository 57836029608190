import { useEffect,useState } from "react";
import { getlogo } from "../services/logoapi";
let cachedData = null;

const uselogo =() => {
    const [logo, setlogo] = useState(cachedData || []);
    const [loading, setLoading] = useState(!cachedData);
    const [error, setError] = useState(null);


    useEffect(()=>{
        if (!cachedData) {
        const fetchData = async()=>{
            try {
                const data = await getlogo();
                cachedData = data; 
                setlogo(data);
            }catch(err){
                console.error('Error fetching blogs:',err);
                setError('Failed to fetch blogs');
            }finally{
                setLoading(false);
            }
        }
        fetchData();
    }
    },[]);

    return {logo, loading, error};

}

export default uselogo;