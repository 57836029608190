// import "@fontsource/roboto/300.css";
// import "@fontsource/roboto/400.css";
// import "@fontsource/roboto/500.css";
// import "@fontsource/roboto/700.css";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import Home from "./pages/home";
// import About from "./pages/about-us";
// import Services from "./pages/services";
// import Solutions from "./pages/solutions";
// import { DataProvider } from "./context/menucontext";
// import Contact from "./pages/contact-us";
// import ItemPage from "./layouts/dynamicpage";

// import './components/theme-constants/color/color.css';
// import './components/theme-constants/global/global.css';

// import { Route, Routes } from "react-router-dom";
// import Header from "./components/header";
// import Footer from "./components/footer";

// import './components/theme-constants/global/responsive.css';

// const theme = createTheme({
//   palette: {
//     primary: {
//       light: "#8fb6f0",
//       main: "#2B74E2",
//       dark: "#124187",
//       contrastText: "#fff",
//     },
//     secondary: {
//       light: "#ed9491",
//       main: "#DE3730",
//       dark: "#841915",
//       contrastText: "#000",
//     },
//   },
// });

// function App() {
//   return (
//     <DataProvider>
//     <ThemeProvider theme={theme}>
//       <div className="theme">
//         <Header />
//         <Routes>
//           <Route path="/" Component={<Home />} />
//           <Route path="/contact" Component={<Contact />} />
//           <Route path="/:slug" Component={<ItemPage />} />
//         </Routes>
//         <Footer />
//       </div>
//     </ThemeProvider>
//   </DataProvider>
//   );
// }

// export default App;


import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Home from "./pages/home";
import About from "./pages/about-us";
import Services from "./pages/services";
import Solutions from "./pages/solutions";
import { DataProvider } from "./context/menucontext";
import Contact from "./pages/contact-us";
import BlogDetail from './layouts/blogdetail.js';
import ItemPage from "./layouts/dynamicpage";
import './components/theme-constants/color/color.css';
import './components/theme-constants/global/global.css';
import { Route, Routes } from "react-router-dom";
import Header from "./components/header";
import Footer from "./components/footer";
import ServiceDetail from "./layouts/servicedetail.js";
import './components/theme-constants/global/responsive.css';

const theme = createTheme({
  palette: {
    primary: {
      light: "#8fb6f0",
      main: "#2B74E2",
      dark: "#124187",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ed9491",
      main: "#DE3730",
      dark: "#841915",
      contrastText: "#000",
    },
  },
});

function App() {
  return (
    <DataProvider>
      <ThemeProvider theme={theme}>
        <div className="theme">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/:slug" element={<ItemPage />} />
            <Route path="/services/:id" element={<ServiceDetail />} />
            <Route path="/blog/:id" element={<BlogDetail />} />
          </Routes>
          <Footer />
        </div>
      </ThemeProvider>
    </DataProvider>
  );
}

export default App;
