// import React from "react";
// import { Button, ListItem, List, Link,Typography } from "@mui/material";
// import { useDataContext } from "../../context/menucontext";
// import "./nav.css";
// function Nav() {

//   const { data, loading, error } = useDataContext(); 

//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error}</p>;
//   return (
//     <nav className="nav" align="right">
//   <List>
//     <ListItem>
//       <Typography
//         variant="h6"
//         to="/"
//         style={{ textDecoration: 'none' }} // Ensure no underline
//       >
//         Home
//       </Typography>
//     </ListItem>
//     {data.map((item) => (
//       <ListItem key={item.id}>
//         <Typography
//           variant="h6"
//           to={`/${item.slug}`}
//           style={{ textDecoration: 'none' }} // Ensure no underline
//         >
//           {item.title}
//         </Typography>
//       </ListItem>
//     ))}

//     <ListItem>
//       <Button
//         className="top-button"
//         variant="contained" // Replace href with Link
//         to="/contact"   // Use 'to' for internal navigation
//         fontWeight={400}
//       >
//         Contact us
//       </Button>
//     </ListItem>
//   </List>
// </nav>
//   );
// }
// export default Nav;


import React from "react";
import { Button, ListItem, List, Link, Typography } from "@mui/material";
import { useDataContext } from "../../context/menucontext";
import { Link as RouterLink } from "react-router-dom"; // Import Link from React Router
import "./nav.css";

function Nav() {
  const { data, loading, error } = useDataContext();
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <nav className="nav" align="right">
      <List>
        <ListItem>
          <Typography
            variant="h6"
            component={RouterLink} // Use RouterLink for navigation
            to="/"
            style={{ textDecoration: 'none' }}
          >
            Home
          </Typography>
        </ListItem>
        {data.map((item) => (
          <ListItem key={item.id}>
            <Typography
              variant="h6"
              component={RouterLink} // Use RouterLink for navigation
              to={`/${item.slug}`}
              style={{ textDecoration: 'none' }}
            >
              {item.title}
            </Typography>
          </ListItem>
        ))}

        <ListItem>
          <Button
            className="top-button"
            variant="contained"
            component={RouterLink} // Use RouterLink for navigation
            to="/contact"           // Use 'to' for internal navigation
            fontWeight={400}
          >
            Contact us
          </Button>
        </ListItem>
      </List>
    </nav>
  );
}

export default Nav;
