// InnerBanner.js
import React from "react";
import { Box, Container, Typography } from "@mui/material";
import { CMS_IMAGES } from "../../constants";
import "../inner-banner/innerbanner.css";

const InnerBanner = ({ bannerImage, title, tagline,path }) => {
  return (
    <Box component="section" className="inner-banner">
      <Box className="pic">
        <Box
          component="img"
          src={`${path}${bannerImage}`}
          alt="Banner"
          sx={{ width: "100%" }}
        />
      </Box>
      <Box className="inner-text">
        <Container>
          <Typography
            variant="h1"
            component="h1"
            color="white"
            align="center"
            className="font700"
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            component="p"
            color="white"
            align="center"
            sx={{ mt: 2 }}
          >
            {tagline}
          </Typography>
        </Container>
      </Box>
    </Box>
  );
};

export default InnerBanner;