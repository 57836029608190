import React from 'react';
import { Box, Container, Grid, Typography } from "@mui/material";
import useStats from '../../../hooks/usestats';


function Experience() {

    const { statistics, loading, error } = useStats();
    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error.message}</p>;

    return (
        <Box className="about-experience-section" sx={{ py: 13 }}>
            <Container maxWidth="xl" className="container">
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    {statistics.map((item, index) => (
                    <Grid item key={index} className="block">
                        <Typography variant="p" component="p">
                            {item.title} 
                        </Typography>
                        <Typography variant="h6" component="h6">
                            {item.number} {item.suffix}
                        </Typography>
                    </Grid>
                ))}
                </Grid>
            </Container>
        </Box>
    );
}

export default Experience;