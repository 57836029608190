import { useEffect,useState } from "react";
import { getDevelopmentprocess } from "../services/developmentapi";

let cachedData = null; 


const useDevelopmentProcess =() => {
    const [development, setdevelopment] = useState(cachedData || []);
    const [loading, setLoading] = useState(!cachedData);
    const [error, setError] = useState(null);


    useEffect(()=>{
        if (!cachedData) {
        const fetchData = async()=>{
            try {
                const data = await getDevelopmentprocess();
                cachedData = data; 
                setdevelopment(data);
            }catch(err){
                console.error('Error fetching blogs:',err);
                setError('Failed to fetch blogs');
            }finally{
                setLoading(false);
            }
        }
        fetchData();
    }
    },[]);

    return {development, loading, error};

}

export default useDevelopmentProcess;