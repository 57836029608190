// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getServices } from '../services/serviceapi';

let cachedData = null;

const useServices = () => {
  const [service, setservice] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) { 
    const fetchData = async () => {
      try {
        const data = await getServices();
        cachedData = data;  // Cache the data
        setservice(data);
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };
    fetchData();
  }
  }, []);

  return { service, loading, error };
};

export default useServices;
