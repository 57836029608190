// hooks/useHeader.js
import { useState, useEffect } from 'react';
import { getStats } from '../services/stats';
let cachedData = null;

const useStats = () => {
  const [statistics, setstatistics] = useState(cachedData || []); 
  const [loading, setLoading] = useState(!cachedData);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!cachedData) {
    const fetchData = async () => {
        try {
          const data = await getStats();
          cachedData = data; 
          setstatistics(data);
      } catch (err) {
        console.error('Error fetching menu:', err); // Improved logging
        setError('Failed to fetch menu'); // Store readable error message
      } finally {
        setLoading(false); // Ensure loading state is handled correctly
      }
    };

    fetchData();
  }
  }, []);

  return { statistics, loading, error };
};

export default useStats;
